import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoginError, UserCredentials} from '../model';
import {AuthActions} from '../store/actions';
import {ActivatedRoute, Router} from '@angular/router';
import {select, select$} from '@angular-redux/store';
import {from, Observable, Subscription} from 'rxjs';
import {filter, flatMap, map} from 'rxjs/operators';
import {AuthState} from '../store/reducers';
import {UserDetails, UserState} from '../../user/model';
import {state, style, trigger} from '@angular/animations';
import {CasProvider} from '../cas/model';
import {ThemeState} from '../../theme/store/reducers';
import {UserActions} from '../../user/store/actions';
import {PASSWORD_RECOVER_STATE} from '../../user/store/reducers';
import {hostname} from 'os';
import {ICustomThemeConfig} from '@shared/app/theme/model';
import {ThemeActions} from '@shared/app/theme/store/actions';

const boolCheck = (error: Observable<boolean>) => error.pipe(
  filter(notNull => !!notNull),
  map(boolValue => boolValue === true)
);

export interface LoginEvents {
  onLoginFailed();
}

@Component({
  selector: 'app-login',
  templateUrl: './component.html',
  styleUrls: ['./component.scss'],
  animations: [
    trigger('loginForm', [
      state('on', style({display: 'block'})),
      state('off', style({display: 'none'}))/*,
        transition('off => on', [ animate('250ms') ]),
        transition('on => off', [ animate('500ms') ])*/
    ]),
    trigger('forgotPsw', [
      state('on', style({display: 'block'})),
      state('off', style({display: 'none'}))/*,
        transition('off => on', [ animate('250ms') ]),
        transition('on => off', [ animate('500ms') ])*/
    ])
  ]
})
export class LoginComponent implements LoginEvents, OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  @select(['auth'])
  readonly authState!: Observable<AuthState>;

  @select(['user', 'passwordRecoverState'])
  readonly passwordRecoverState: Observable<PASSWORD_RECOVER_STATE>;

  @select$(['auth', 'login_failed'], boolCheck)
  readonly loginFailed!: Observable<boolean>;

  @select(['auth', 'login_error'])
  readonly loginError!: Observable<LoginError>;

  @select(['auth', 'casProviders'])
  readonly casProviders!: Observable<CasProvider[]>;

  @select(['auth', 'login_started'])
  readonly loginStarted!: Observable<boolean>;

  @select(['theme'])
  readonly themeState!: Observable<ThemeState>;

  @select(['theme', 'config'])
  readonly themeConfig!: Observable<ICustomThemeConfig>;

  credentials: UserCredentials = {username: '', password: '', hostname: ''};
  selectedCasProvider: CasProvider = undefined;

  readonly showLangSelection: boolean;
  readonly languages;
  loginFormState = 'on';
  forgotPswState = 'off';
  selectedLanguageCode: string;
  recoverPasswordEmail: string;
  loading = false;
  loadingR = false;
  recoverPswResult: boolean;
  returnUrl: string;


  constructor(
    private actions: AuthActions,
    private userActions: UserActions,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  login = () =>
    this.selectedCasProvider
      ? window.location.href = this.selectedCasProvider.url
      : this.actions.loginUser({...this.credentials, hostname: window.location.hostname});

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
    this.subscriptions.push(
      this.authState.pipe(
        filter(state => !!state && state.login_succeeded),
        flatMap(() => from(this.router.navigate([this.returnUrl]))),
      ).subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  toggleRecoverPsw(): void {
    this.loginFormState = this.loginFormState === 'on' ? 'off' : 'on';
    this.forgotPswState = this.forgotPswState === 'on' ? 'off' : 'on';
  }


  recover() {
    this.userActions.recoverPassword(this.recoverPasswordEmail);
  }

  onLoginFailed = () => {
  };

  recoverButtonDisabled = (): Observable<boolean> =>
    this.passwordRecoverState.pipe(
      map(state => !!state && (state === PASSWORD_RECOVER_STATE.recovering))
    );

  passwordRecovered = (): Observable<boolean> =>
    this.passwordRecoverState.pipe(
      map(state => !!state && (state === PASSWORD_RECOVER_STATE.recovered))
    );

  passwordRecoverFailed = (): Observable<boolean> =>
    this.passwordRecoverState.pipe(
      map(state => !!state && (state === PASSWORD_RECOVER_STATE.failed))
    );

  googleLinkUrl = (): Observable<string> =>
    this.themeConfig.pipe(
      map(state => {
        return '/iam/api/v1/login/' + state.hierarchy + '/googleOAuth';
      })
    );
}
