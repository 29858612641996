<div class="col-sm-12 col-md-6 home-box-container">
  <div class="home-box">
    <div class="home-box-title">
      <i class="fas fa-tachometer-alt"></i>&nbsp;
      <span translate>My dashboard</span>
    </div>
    <div class="home-box-content text-center">

      <div class="row justify-content-center" *ngIf="!(userStats$ | async) as stats">
        <div class="col-2\">
          <div [spinner]="true"></div>
        </div>
      </div>
      <div *ngIf="userStats$ | async as stats" class="home-widgets row justify-content-center mt-5">
        <div class="col-6">
          <div class="home-widget-col">
            <div *ngTemplateOutlet="widgetTotalHoursNotCompletedTemplate;context:{stats: stats.all}"></div>
          </div>
        </div>
        <div class="col-6">
          <div class="home-widget-col">
            <div *ngTemplateOutlet="widgetTotalHoursCompleted;context: {stats: stats.year}"></div>
          </div>

        </div>
      </div>
      <!--      <a *ngIf="(userStats$ | async) as stats" (click)="loadUserStats()" class="moreinfo cursor-pointer"><i-->
      <!--        class="fas fa-sync-alt"></i>&nbsp;<span-->
      <!--        translate>reload</span></a><br>-->
      <div class="home-box-btn-container">
        <a class="btn btn-enter" [routerLink]="['/report/user']"><i class="far fa-caret-square-right"></i>&nbsp;<span
          translate>Details</span></a>
      </div>
    </div>
    <div>
    </div>
  </div>
</div>
<div class="col-sm-12 col-md-6 home-box-container">
  <div class="home-box">
    <div class="home-box-title">
      <i class="fa fa-user"></i>&nbsp;
      <span translate>My courses</span>
    </div>
    <div class="home-box-content"><span translate>Browse the course you're enrolled in, and access both mandatory and elective learning materials.</span>
    </div>
    <div class="home-box-btn-container">
      <a class="btn btn-enter" [routerLink]="['/courses']"><i class="far fa-caret-square-right"></i>&nbsp;<span
        translate>Enter</span></a>
    </div>
  </div>
</div>

<div class="col-sm-12 col-md-6 home-box-container">
  <div class="home-box">
    <div class="home-box-title">
      <i class="fa fa-book"></i>&nbsp;
      <span translate>Catalog</span>
    </div>
    <div class="home-box-content"><span translate>Browse and choose additional courses to enroll in.</span>
    </div>
    <div class="home-box-btn-container">
      <a class="btn btn-enter" [routerLink]="['/catalog']"><i class="far fa-caret-square-right"></i>&nbsp;<span
        translate>Enter</span></a>
    </div>
  </div>
</div>
<!--<div class="col-sm-12 col-md-6 home-box-container">-->
<!--  <div class="home-box">-->
<!--    <div class="home-box-title">-->
<!--      <i class="fas fa-chart-area"></i>&nbsp;-->
<!--      <span translate>My dashboard</span>-->
<!--    </div>-->
<!--    <div class="home-box-content"><span-->
<!--      translate>Visualize your learning journey and the status of all your courses.</span>-->
<!--    </div>-->
<!--    <div class="home-box-btn-container">-->
<!--      <a class="btn btn-enter" [routerLink]="['/report/user']"><i-->
<!--        class="far fa-caret-square-right"></i>&nbsp;<span translate>Enter</span></a>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<div class="col-sm-12 col-md-6 home-box-container">
  <div class="home-box box-lessons">
    <div class="home-box-title">
      <i class="fa fa-calendar"></i>&nbsp;
      <span translate>My lessons</span>
    </div>
    <div class="home-box-content">
      <h5 *ngIf="userSessions.length === 0" class="text-center">
        <span translate>No lessons available</span>
      </h5>
      <app-slide-show [slides]="userSessions" minHeight="150" arrowColor="#000000" >
        <ng-template appSlideItem *ngFor="let lesson of userSessions">
          <div class="carousel-caption d-none d-md-block cursor-pointer">
            <div [routerLink]="['/path',lesson.pathId,'course', lesson.courseId]">
              <h4>
                {{ lesson.lessonDescription | substring : 80 }}
              </h4>
<!--              <h6>-->
<!--                {{ lesson.description | substring : 60 }}-->
<!--              </h6>-->
              <p [routerLink]="['/path',lesson.pathId,'course', lesson.courseId]">
                <i
                  class="fa fa-calendar"></i>&nbsp;{{ lesson.date_start | date : 'd/MM/yyyy' }} {{ lesson.date_start | date : 'HH:mm' }}
              </p>
            </div>
          </div>
        </ng-template>
      </app-slide-show>
    </div>
    <div class="home-box-btn-container">
      <a class="btn btn-enter" [routerLink]="['/calendar']"><i class="far fa-caret-square-right"></i>&nbsp;<span
        translate>Enter</span></a>
    </div>
  </div>
</div>


<ng-template #widgetTotalHoursNotCompletedTemplate let-stats="stats">
  <div class="home-widget-cell cell-1">
    <span class="big-number">{{stats.totalCourseCanDo}}</span>
  </div>
  <div class="home-widget-cell cell-1">
    <span translate class="text-lowercase">Available courses not completed</span>
  </div><br>
  <div class="home-widget-cell cell-1">
    <span
      class="big-number"
      [appDuration]="stats.totalHoursCanDo"
      [format]="'minutes'" [showNumberIfNullOrZero]="true" minutesSmall="50">
                    </span>
  </div>
  <div class="home-widget-cell cell-1">
    <span translate class="text-lowercase">Available hours not completed</span>
  </div><br><br>

</ng-template>

<ng-template #widgetTotalHoursCompleted let-stats="stats">
  <div class="home-widget-cell cell-1">
    <span class="big-number">{{stats.totalCourseCompleted}}</span>
  </div>
  <div class="home-widget-cell cell-1">
    <span translate class="text-lowercase">Total courses completed</span>
  </div><br><br>
    <div class="home-widget-cell cell-1">
      <span
        class="big-number"
        [appDuration]="stats.totalHoursCourseCompleted"
        [format]="'minutes'" [showNumberIfNullOrZero]="true" minutesSmall="50">
      </span>
    </div>
    <div class="home-widget-cell cell-1">
      <span translate class="text-lowercase">Total hours completed in the current year</span>
    </div><br><br>
</ng-template>


<!--<ng-template #widgetToBeCompletedHoursByTypeChartTemplate let-stats="stats">-->
<!--  <div class="home-widget-cell cell-1">-->
<!--    <canvas baseChart *ngIf="stats.hoursCounter && stats.hoursCounter > 0"-->
<!--            [data]="stats.doughnutChartData"-->
<!--            [labels]="stats.doughnutChartLabels"-->
<!--            [chartType]="stats.doughnutChartType"-->
<!--            [legend]="false"-->
<!--            [colors]="colors">-->
<!--    </canvas>-->
<!--    <div class="widget-number" [hidden]="stats.hoursCounter > 0">-->
<!--      <span style="font-size:30px;">N/A</span>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="home-widget-cell cell-2">-->
<!--    <div class="widget-text">-->
<!--      <span *ngIf="stats.counters">{{ stats.counters.toDoMandatoryHours + stats.counters.toDoOptionalHours }}</span>&nbsp;<span-->
<!--      translate class="text-lowercase">Hours left</span>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->

<!--<ng-template #widgetCompletedHoursByTypeChartTemplate>-->
<!--  <div *ngIf="userStats$ | async as stats" class="home-widget-cell cell-1">-->
<!--    <canvas baseChart *ngIf="stats.hoursCounter && stats.hoursCounter > 0"-->
<!--            [data]="stats.doughnut2ChartData"-->
<!--            [labels]="stats.doughnut2ChartLabels"-->
<!--            [chartType]="stats.doughnutChartType"-->
<!--            [legend]="false"-->
<!--            [colors]="colors">-->
<!--    </canvas>-->
<!--    <div class="widget-number" [hidden]="stats.hoursCounter > 0">-->
<!--      <span style="font-size:30px;">N/A</span>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="home-widget-cell cell-2">-->
<!--    <div *ngIf="userStats$ | async as stats" class="widget-text">-->
<!--      <strong-->
<!--        *ngIf="stats.counters">{{ stats.counters.mandatoryCompletedHours + stats.counters.optionalCompletedHours }}</strong><br/><span-->
<!--      translate>done hours</span>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->

<!--<ng-template #widgetHoursByTypeChartTemplate>-->
<!--  <div *ngIf="userStats$ | async as stats" class="home-widget-cell cell-1">-->
<!--    <canvas baseChart *ngIf="stats.hoursCounter && stats.hoursCounter > 0"-->
<!--            [data]="stats.doughnut4ChartData"-->
<!--            [labels]="stats.doughnut4ChartLabels"-->
<!--            [chartType]="stats.doughnutChartType"-->
<!--            [legend]="false"-->
<!--            [colors]="colors">-->
<!--    </canvas>-->
<!--    <div class="widget-number" [hidden]="stats.hoursCounter > 0">-->
<!--      <span style="font-size:30px;">N/A</span>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="home-widget-cell cell-2">-->
<!--    <div class="widget-text">-->
<!--      <span translate>hours by type</span>:-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->

<!--<ng-template #widgetCoursesByStatusChartTemplate let-stats="stats">-->
<!--  <div class="home-widget-cell cell-1">-->
<!--    <canvas baseChart *ngIf="stats.coursesCounter && stats.coursesCounter > 0"-->
<!--            [data]="stats.doughnut3ChartData"-->
<!--            [labels]="stats.doughnut3ChartLabels"-->
<!--            [chartType]="stats.doughnutChartType"-->
<!--            [legend]="false"-->
<!--            [colors]="colors">-->
<!--    </canvas>-->
<!--    <div class="widget-number" [hidden]="stats.coursesCounter > 0">-->
<!--      <span style="font-size:30px;">N/A</span>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div *ngIf="userStats$ | async as stats" class="home-widget-cell cell-2">-->
<!--    <div class="widget-text">-->
<!--      <span>{{ stats.coursesCounter }} </span><span translate class="text-lowercase">Active courses</span>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->
